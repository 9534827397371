import React, { Component } from 'react';
const loader = require("../Images/loader.gif");

const card_bg_1 = require("../Images/card_bg_1.jpg");
const card_bg_2 = require("../Images/card_bg_2.jpg");
const card_bg_3 = require("../Images/card_bg_3.jpg");
const card_bg_4 = require("../Images/card_bg_4.jpg");
const card_bg_5 = require("../Images/card_bg_5.jpg");
const card_bg_6 = require("../Images/card_bg_6.jpg");

export default {loader, card_bg_1, card_bg_2, card_bg_3, card_bg_4, card_bg_5, card_bg_6}